import React from 'react';

const Home = () => (
	<div style={{ marginTop: '4em', marginBottom: '4em' }}>
		<p>
			My name is Andy Hill. I am a programmer in <a href="http://bloomington.in.gov/" target="_blank" rel="noreferrer">Bloomington, Indiana</a>. &nbsp;
			Primarily a web developer. I got my Bachelor of Science in Computer Science from &nbsp;
			<a href="https://www.iu.edu/" target="_blank" rel="noreferrer">Indiana University</a> I currently work at IU in&nbsp;
			<a href="https://uits.iu.edu/" target="_blank" rel="noreferrer"> &nbsp;
			<abbr title="University Information Technology Services">UITS</abbr></a>&apos;s &nbsp;
			Enterprise Platforms Team. There is no relationship between Indiana University and this site.
		</p>
	</div>

);

export default Home;
